import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/alumni-workshops-website/alumni-workshops-website/src/components/workshop-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Survey of Frontend Architectures`}</h1>
    <h2>{`2022-03-23 at 6:30pm`}</h2>
    <p><em parentName="p">{`Duration: 1 hour`}</em></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20220324T013000Z%2F20220324T023000Z&details=In%20this%20session%2C%20you%27ll%20find%20a%20high-level%20introduction%20to%20frontend%20architecture%20patterns%2C%20learning%20what%20they%20are%20and%20when%20to%20apply%20them.%20We%27ll%20start%20by%20exploring%20approaches%20to%20simple%2C%20static%20sites%20and%20gradually%20increase%20design%20complexity.&location=http%3A%2F%2Flovelace-labs.adadev.org&text=Survey%20of%20Frontend%20Architecture%20with%20Nina%20Mutty"
        }}>{`Add To Google Calendar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://outlook.live.com/calendar/0/deeplink/compose?allday=false&body=In%20this%20session%2C%20you%27ll%20find%20a%20high-level%20introduction%20to%20frontend%20architecture%20patterns%2C%20learning%20what%20they%20are%20and%20when%20to%20apply%20them.%20We%27ll%20start%20by%20exploring%20approaches%20to%20simple%2C%20static%20sites%20and%20gradually%20increase%20design%20complexity.&enddt=2022-03-24T02%3A30%3A00%2B00%3A00&location=http%3A%2F%2Flovelace-labs.adadev.org&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-03-24T01%3A30%3A00%2B00%3A00&subject=Survey%20of%20Frontend%20Architecture%20with%20Nina%20Mutty"
        }}>{`Add to Microsoft Calendar`}</a></li>
    </ul>
    <p>{`In this session, you'll find a high-level introduction to frontend architecture patterns, learning what they are and when to apply them. We'll start by exploring approaches to simple, static sites and gradually increase design complexity as we introduce new product and process requirements. By the end of the session, students will be able to:`}</p>
    <ul>
      <li parentName="ul">{`Gather relevant product and organizational requirements`}</li>
      <li parentName="ul">{`Understand the purpose of various modern frontend web technologies such as static site generators, state management, web workers, server-side rendering, micro-frontend architectures, and more.`}</li>
      <li parentName="ul">{`Map requirements to technologies that solve for those specific use cases.`}</li>
    </ul>
    <p>{`This session will combine technology overviews and case studies to give students a deeper understanding of the patterns discussed.`}</p>
    <h2>{`Nina Mutty`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e3288e8e69d94bbd4fde5a8c704124ac/f93b5/nina-mutty-thumb.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAUEAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAADAQL/2gAMAwEAAhADEAAAAbGD0lY1ddWZZoT0Qg//xAAcEAACAgIDAAAAAAAAAAAAAAABAgMRAAQTITP/2gAIAQEAAQUCJoJOWObTVFzdg2J/Rs1TcP8A/8QAGBEBAQADAAAAAAAAAAAAAAAAAQAQMTL/2gAIAQMBAT8BDBzO7//EABcRAQADAAAAAAAAAAAAAAAAAAABEEL/2gAIAQIBAT8BrSH/xAAdEAACAQQDAAAAAAAAAAAAAAAAAQIQETFBAyGB/9oACAEBAAY/Am3ojdJKeKNbZxdYLnlEf//EABwQAQACAgMBAAAAAAAAAAAAAAEAESExEEFRcf/aAAgBAQABPyEMQBbPEsGz7wfXstcqtz1hGNMF5SaMRLU//9oADAMBAAIAAwAAABBvB3//xAAXEQEBAQEAAAAAAAAAAAAAAAAAETEB/9oACAEDAQE/EK8qMmz/xAAZEQACAwEAAAAAAAAAAAAAAAAAARARMSH/2gAIAQIBAT8Qb7UPRg//xAAbEAEBAQEBAAMAAAAAAAAAAAABEQBBIVFxof/aAAgBAQABPxBuqifAa01IU95689nNF1sokJw7oP8AJjWEpmlo0cbAoCfuKwjkV1FD6u//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Nina Mutty thumbnail",
            "title": "Nina Mutty thumbnail",
            "src": "/static/e3288e8e69d94bbd4fde5a8c704124ac/f93b5/nina-mutty-thumb.jpg",
            "srcSet": ["/static/e3288e8e69d94bbd4fde5a8c704124ac/f93b5/nina-mutty-thumb.jpg 300w"],
            "sizes": "(max-width: 300px) 100vw, 300px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Nina Mutty is a Staff Software Engineer at Indeed, a Master's student in Computer Science at Georgia Tech, and a fellow Ada Developers Academy alumni (C6)! She has full-stack development experience but particularly enjoys the challenges that arise on the front end. She is passionate about building reliable, stable, and fast products for users to enjoy and loves sharing what's she's learned along the way.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      